/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// import '@babel/polyfill'
import React from 'react'
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/scss/notification.scss'
import 'animate.css'
import AvailabilityProvider from './src/hooks/availabilityContext'
import WorkerAffordabilityProvider from './src/hooks/workerAffordabilityContext'

export const wrapRootElement = ({ element }) => (
  <AvailabilityProvider>
    <WorkerAffordabilityProvider>
      <ReactNotifications />
      {element}
    </WorkerAffordabilityProvider>
  </AvailabilityProvider>
)
